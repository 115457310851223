import styled from "@emotion/styled"

const Heading3 = styled.h3`
  font-family: ${props => props.theme.typography.standardFamily};
  font-weight: 600;
  font-size: 160%;
  line-height: 1.2;
  letter-spacing: 0.1em;
  margin-bottom: 0.5em;
  @media ${({ theme }) => theme.breakpoint.desktop} {
    font-size: 155%;
  }
`
export default Heading3
